* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

*:focus {
  border: none;
  outline: none;
}

/* Estilização da scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Largura da scrollbar vertical */
}

::-webkit-scrollbar-thumb {
  background-color: #D9D9D9; /* Cor do polegar da scrollbar */
  border-radius: 5px; /* Borda arredondada do polegar da scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Cor da trilha da scrollbar */
}
